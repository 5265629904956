import React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

import "./css/404.scss"

const NotFoundPage = () => (
  <Layout>
    <div className="page">
      <h1>404</h1>
      <p>Die gewünschte Seite existiert leider nicht.</p>
      <Link to="/#top" className="button">
        <Button variant="danger">Zur Startseite</Button>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
